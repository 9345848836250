'use client';

import Box from '@/components/ui/Box';
import { useTranslations } from 'next-intl';

type EntryIndexNoResultsProps = {
  message?: string;
};

const EntryIndexNoResults = ({ message: customMessage }: EntryIndexNoResultsProps) => {
  const t = useTranslations('entryIndex');

  const message = customMessage || t('noResults');

  return <Box cx={{ pY: 'md' }}>{message}</Box>;
};

export default EntryIndexNoResults;
