import { CategoryCriteriaInput, EntryCriteriaInput } from '@/__generated__/graphql';
import { ListElement } from '@/lib/parsers/common/parseElementList';
import {
  EntryIndexActions,
  EntryIndexState,
  EntryIndexStateWithActions,
  EntryIndexVariables,
  FilterSliceState,
} from './entryIndexTypes';

export const selectIndexQueryVariables = (state: EntryIndexState): EntryIndexVariables => {
  const { search, order, limit, filters, filterSelections, baseEntryQuery, page = 1 } = state;

  const queryVariables: EntryIndexVariables = {
    ...baseEntryQuery,
    search: search ?? undefined,
    limit,
    offset: (page - 1) * limit,
    orderBy: order,
  };

  const relatedToCategories: CategoryCriteriaInput[] = [];
  const relatedToEntries: EntryCriteriaInput[] = [];

  Object.entries(filters).forEach(([filterId, filter]) => {
    const { relation, type } = filter;
    const selection = filterSelections[filterId] ?? [];

    if (selection.length === 0) return;

    const filterItem = { id: [relation, ...selection] };

    if (type === 'category') {
      relatedToCategories.push(filterItem);
    } else if (type === 'entry') {
      relatedToEntries.push(filterItem);
    }
  });

  if (relatedToCategories.length > 0) {
    queryVariables.relatedToCategories = relatedToCategories;
  }

  if (relatedToEntries.length > 0) {
    queryVariables.relatedToEntries = relatedToEntries;
  }

  return queryVariables;
};

export const selectFilterSlice =
  (sliceId: string) =>
  (state: EntryIndexState): FilterSliceState | undefined =>
    state.filters[sliceId];

export const selectIsFiltered = (state: EntryIndexState): boolean => {
  return (
    !!state.search ||
    Object.values(state.filterSelections).some((selection) => !!selection && selection.length > 0)
  );
};

export const selectActiveFilters = (state: EntryIndexState) => {
  return Object.entries(state.filterSelections).reduce((acc, [filterId, selection]) => {
    if (!selection?.length) return acc;

    const allFilterItems = state.filters[filterId].items ?? {};

    return [...acc, ...selection.map((id) => allFilterItems[id])];
  }, [] as ListElement[]);
};

export const selectActions = (state: EntryIndexStateWithActions): EntryIndexActions => {
  const {
    setState,
    setSearch,
    setOrder,
    setItems,
    resetFilters,
    setFilterSelections,
    filterReplace,
    filterSelect,
    filterRemove,
    filterClear,
    setLoading,
    setError,
    setInitialLoaded,
    setLimit,
  } = state;
  return {
    setState,
    setSearch,
    setOrder,
    setItems,
    resetFilters,
    setFilterSelections,
    filterReplace,
    filterSelect,
    filterRemove,
    filterClear,
    setLoading,
    setError,
    setInitialLoaded,
    setLimit,
  };
};
