import { gql } from '@/__generated__';

export const LIST_ENTRIES_QUERY = gql(`
  query listEntries($limit: Int, $section: [String], $type: [String], $relatedToEntries: [EntryCriteriaInput] = null) {
    entries(
      relatedToEntries: $relatedToEntries
      limit: $limit
      section: $section
      type: $type
    ) {
      ...entryId
    }
  }
`);

export const LIST_CATEGORIES_QUERY = gql(`
  query listCategories($group: [String], $relatedToEntries: [EntryCriteriaInput] = null) {
    categories(group: $group, relatedToEntries: $relatedToEntries) {
      ...categoryId
    }
  }
`);

export const SEARCH_ENTRIES_QUERY = gql(`
  query searchEntries(
      $limit: Int = 12,
      $offset: Int = 0,
      $section: [String],
      $type: [String],
      $search: String,
      $orderBy: String,
      $relatedToCategories: [CategoryCriteriaInput],
      $relatedToEntries: [EntryCriteriaInput]
    ) {
    entries(
      limit: $limit
      offset: $offset
      section: $section
      type: $type
      search: $search
      orderBy: $orderBy
      relatedToCategories: $relatedToCategories
      relatedToEntries: $relatedToEntries
    ) {
      ...entryCards
    }
    entryCount(
      section: $section
      type: $type
      search: $search
      relatedToCategories: $relatedToCategories
      relatedToEntries: $relatedToEntries
    )
  }
`);
