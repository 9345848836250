import { makeElementTree } from '@/lib/parsers/common/makeElementTree';
import { ElementTreeItem } from '@/lib/parsers/common/parseElementList';
import React from 'react';
import { useEntryIndex } from './useEntryIndex';

// ----------------------------------------------------------------------------

// Create context for a filter slice

// export const { Provider: FilterContextProvider } = React.createContext<
//   FilterSliceState | undefined
// >(undefined);

export const useEntryIndexFilter = (filterId: string) => {
  const { add, remove, clear, replace } = useEntryIndex((s) => {
    return {
      add: (value: string) => s.filterSelect(filterId, value),
      remove: (value: string) => s.filterRemove(filterId, value),
      clear: () => s.filterClear(filterId),
      replace: (value: string) => s.filterReplace(filterId, value),
    };
  });

  const { items = {}, label } = useEntryIndex((s) => s.filters[filterId]);

  const itemTree = React.useMemo(
    () => makeElementTree(Object.values(items ?? {}), filterId),
    [filterId, items]
  );

  const selection = useEntryIndex((s) => s.filterSelections[filterId] ?? []);

  return { selection, items, itemTree, label, add, remove, clear, replace };
};

export const useEntryIndexFilterItem = (item: ElementTreeItem) => {
  const { id, collectionId } = item;
  const { selection, add, remove, clear } = useEntryIndexFilter(collectionId);

  const active = selection.includes(id);

  const change = React.useCallback(() => {
    clear();
    add(id);
  }, [id, clear, add]);

  const toggle = React.useCallback(
    (force?: boolean) => {
      const newValue = force ?? !active;
      if (newValue) {
        add(id);
      } else {
        remove(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active]
  );

  return { active, toggle, change };
};

// ----------------------------------------------------------------------------
