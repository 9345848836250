import Card from '../Card';
import Divider from '../ui/Divider';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';

export type ProfileCardProps = EntryCardProps<'profile'>;

export const ProfileCard = ({ item, ...props }: ProfileCardProps) => {
  const { uri: href, firstName, lastName, label } = item;

  return (
    <Card
      elevation={false}
      fullHeight
      paper
      {...props}
      item={{
        href,
        title: firstName + ' ' + lastName,
        description: label,
      }}>
      <Divider direction="horizontal" color="accent" size="md" />
      <Card.Body cx={{ display: 'flex', rowGap: '2xs', mT: 'xs' }}>
        <Card.Title variant="h5" as="span" hover={false} />
        <Card.Description variant="body" as="span" />
      </Card.Body>
    </Card>
  );
};
