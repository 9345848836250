'use client';

import { EntriesFragment } from '@/__generated__/graphql';
import { SEARCH_ENTRIES_QUERY } from '@/gql/queries/entryIndex.gql';
import { parseEntries } from '@/lib/parsers/entries';
import { TypedDocumentNode, useApolloClient } from '@apollo/client';
import React, { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useEntryIndexFilterRegistry } from './Filters/useEntryIndexFilterRegistry';
import { selectActions, selectIndexQueryVariables } from './entryIndexSelectors';
import { EntryIndexQuery, EntryResultItem } from './entryIndexTypes';
import { useEntryIndex } from './useEntryIndex';
import useEntryIndexSearchParams from './useEntryIndexSearchParams';

type EntryIndexQueryHandlerProps<T extends EntryResultItem, Q extends EntryIndexQuery<T>> = {
  /**
   * A custom query to use for the entry index
   * @todo Fix the typing here
   */
  customQuery?: TypedDocumentNode<Q>;
};

export const useEntryIndexQueryHandler = <T extends EntryResultItem, Q extends EntryIndexQuery<T>>(
  props?: EntryIndexQueryHandlerProps<T, Q>
) => {
  const query = props?.customQuery || SEARCH_ENTRIES_QUERY;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { applyFilters } = useEntryIndexFilterRegistry(({ applyFilters, filters }) => ({
    applyFilters,
    filters,
  }));
  const apolloClient = useApolloClient();
  const initialQuery = useEntryIndex(useShallow(selectIndexQueryVariables));
  const variables = initialQuery; //React.useMemo(() => applyFilters(initialQuery), [applyFilters, initialQuery]);
  const { ready } = useEntryIndexSearchParams();

  // Convert variables to string to compare
  const variablesStr = JSON.stringify(variables);

  const actions = useEntryIndex(selectActions);
  const state = useEntryIndex((s) => s);

  const [prevVariablesStr, setPrevVariablesStr] = React.useState('');
  const hasChanges = variablesStr !== prevVariablesStr;

  const triggerQuery = useCallback(
    (force?: boolean) => {
      if (!hasChanges && !force) return;

      actions.setLoading(true);

      const watchedQuery = apolloClient.watchQuery({
        query,
        variables,
        // fetchPolicy: params?.fetchPolicy || "cache-and-network",
      });

      watchedQuery.fetchMore({
        variables: {
          offset: state.items.length,
          limit: state.limit,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            entries: [...(prev.entries || []), ...(fetchMoreResult.entries || [])],
          });
        },
      });

      const sub = watchedQuery.subscribe({
        next(x) {
          if (!x.partial) {
            setPrevVariablesStr(variablesStr);
            const entries = parseEntries(x.data.entries as MaybeArrayOf<EntriesFragment>);
            actions.setItems(entries);
            actions.setError(null);
            actions.setLoading(false);
            actions.setInitialLoaded(true);
          }
        },
        error(err) {
          actions.setError(err);
          actions.setLoading(false);
        },
        complete() {
          actions.setLoading(false);
        },
      });

      return () => {
        sub.unsubscribe();
      };
    },
    [actions, apolloClient, hasChanges, query, state.items.length, state.limit]
  );

  return {
    triggerQuery,
    hasChanges,
    ready,
  };
};
