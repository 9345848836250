import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './EntryCard.css';

export type FeaturedCardProps = EntryCardProps<'article'>;

export const FeaturedCard = ({ item, ...props }: FeaturedCardProps) => {
  const {
    uri: href,
    title,
    summary: description,
    postDate,
    contentTypeCategorySingle,
    entryImage: image,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  return (
    <Card
      elevation={false}
      paper
      {...props}
      item={{
        href,
        title,
        meta: [postDate],
        description,
        image,
      }}>
      <Grid>
        <Grid.Col md={8} style={{ position: 'relative' }}>
          <Txt allCaps className={styles.tag} color="accentContrast">
            {contentTypeName}
          </Txt>
          <Card.Image alt="" ratio="landscapeWide" />
        </Grid.Col>
        <Grid.Col md={4} cx={{ display: 'flex', alignItems: 'center' }}>
          <Card.Body cx={{ mT: { xs: 'md', md: 'none' }, display: 'flex', rowGap: 'xs' }}>
            <Card.Meta allCaps />
            <Card.Title variant="h4" as="span" />
            <Card.Description />
            <Card.Cta as="div" />
          </Card.Body>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
