import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import Box from '../ui/Box';
import Txt from '../ui/Txt';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import * as styles from './EntryCard.css';

export type ArticleCardProps = EntryCardProps<'article'>;

export const ArticleCard = ({ item, ...props }: ArticleCardProps) => {
  const {
    uri: href,
    title,
    pageTitle,
    summary: description,
    postDate,
    entryImage: image,
    contentTypeCategorySingle,
  } = item;
  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  return (
    <Card
      fullHeight
      elevation={false}
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        meta: [postDate],
        description,
        image,
      }}>
      <Box style={{ position: 'relative' }}>
        <Txt aria-label="hidden" allCaps className={styles.tag} color="accentContrast">
          {contentTypeName}
        </Txt>
        <Card.Image alt="" ratio="landscapeWide" />
      </Box>
      <Card.Body cx={{ mT: 'md', display: 'flex', rowGap: 'xs' }}>
        <Card.Meta aria-label="hidden" allCaps />
        <Card.Title variant="h5" />
        <Box className={styles.srOnly}>
          <Card.Meta />
          <Txt>{contentTypeName}</Txt>
        </Box>
        <Card.Description />
        <Card.Cta as="div" />
      </Card.Body>
    </Card>
  );
};
