'use client';

import { EntryIndexState, FilterSelections } from './entryIndexTypes';
import { useEntryIndex } from './useEntryIndex';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React from 'react';

export const stringifySearchParams = (searchParams: URLSearchParams) => {
  const query = new URLSearchParams(searchParams);
  query.sort();

  return query.toString();
};

const selectQueryString = (state: EntryIndexState): string | null => {
  const { page, filterSelections, search } = state;

  const query = new URLSearchParams();

  Object.entries(filterSelections).forEach(([filterId, selection]) => {
    if (!selection) return;
    selection = [...selection].sort((a, b) => a.localeCompare(b));
    selection.forEach((id) => {
      query.append(filterId, id);
    });
  });

  if (page && page > 1) {
    query.append('p', page.toString());
  }

  if (search) {
    query.append('s', search);
  }

  return stringifySearchParams(query) || null;
};

export const useEntryIndexSearchParamsSetters = (active: boolean) => {
  const router = useRouter();
  const pathname = usePathname();
  const queryString = useEntryIndex(selectQueryString);
  const searchParams = useSearchParams();
  const currentQueryString = stringifySearchParams(searchParams);

  React.useEffect(() => {
    if (!active) return;
    if (queryString === currentQueryString) return;

    router.push(queryString ? `${pathname}?${queryString}` : pathname);
  }, [active, pathname, queryString, router]);

  return queryString;
};

export default function useEntryIndexSearchParams() {
  const searchParams = useSearchParams();
  const filterIds = useEntryIndex((s) => Object.keys(s.filters));
  const setState = useEntryIndex((s) => s.setState);
  const updateUrl = useEntryIndex((s) => !!s.updateUrl);
  const [initialReady, setInitialReady] = React.useState(false);
  const [loadedParams, setLoadedParams] = React.useState<string>();

  useEntryIndexSearchParamsSetters(initialReady && updateUrl);

  React.useEffect(() => {
    /**
     * If we're not using the updateUrl feature, we don't need to do anything
     */
    if (initialReady || !updateUrl) return;

    if (!filterIds.length) return;

    const search = searchParams.get('s');

    const page = parseInt(searchParams.get('p') ?? '1', 10);

    const filterSelections = filterIds.reduce<FilterSelections>((acc, filterId) => {
      const values = searchParams.getAll(filterId);
      if (values.length) {
        acc[filterId] = values;
      }
      return acc;
    }, {});

    setState({ filterSelections, page, search });
    setInitialReady(true);
  }, [filterIds, initialReady, searchParams, setState, updateUrl]);

  React.useEffect(() => {
    const paramsString = stringifySearchParams(searchParams);
    if (paramsString && loadedParams === paramsString) return;
    setLoadedParams(paramsString);
    // console.log('searchParams changed', paramsString);

    return () => {
      // console.log('searchParams cleanup');
    };
  }, [loadedParams, searchParams]);

  return {
    ready: initialReady || !updateUrl,
  };
}
