'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense } from 'react';
import createFilterRegistry from '../FilterRegistry/createFilterRegistryContext';
import { EntryIndexVariables } from '../entryIndexTypes';
import { stringifySearchParams } from '../useEntryIndexSearchParams';

const registry = createFilterRegistry<EntryIndexVariables>({} as EntryIndexVariables);

export const { useFilter: useEntryIndexFilter, useFilterRegistry: useEntryIndexFilterRegistry } =
  registry;

type EntryIndexFilterRegistryProviderProps = React.PropsWithChildren<{}>;

const URLWatcher = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const values = useEntryIndexFilterRegistry((s) => s.values);
  const setValues = useEntryIndexFilterRegistry((s) => s.setValues);
  const [loaded, setLoaded] = React.useState(false);
  const [loadedValues, setLoadedValues] = React.useState<string>();

  const query = new URLSearchParams();

  Object.entries(values).forEach(([filterId, value]) => {
    if (!value) return;
    const valueArr = Array.isArray(value) ? value : [value];
    valueArr.forEach((id) => {
      query.append(filterId, `${id}`);
    });
  });

  const queryString = stringifySearchParams(query);
  const currentQueryString = stringifySearchParams(searchParams);

  React.useEffect(() => {
    if (!loaded) return;
    if (queryString === currentQueryString) return;

    router.push(queryString ? `${pathname}?${queryString}` : pathname, { scroll: false });
  }, [currentQueryString, loaded, pathname, queryString, router]);

  React.useEffect(() => {
    if (loaded) return;

    const newValuesString = stringifySearchParams(searchParams);

    if (newValuesString === loadedValues) return;

    setLoadedValues(newValuesString);

    const newFilterValues: Record<string, string[]> = {};
    const entries = Array.from(searchParams.entries());

    entries.forEach(([key, value]) => {
      const valueArr = value.split(',');
      newFilterValues[key] = valueArr;
    });

    setValues(newFilterValues);
    setLoaded(true);
    // console.log('setLoaded');
    return () => {
      // setLoaded(false);
      // console.log('unsetLoaded');
    };
  }, [loaded, loadedValues, searchParams, setValues]);

  return null;
};

export const EntryIndexFilterRegistryProvider = ({
  children,
}: EntryIndexFilterRegistryProviderProps) => {
  return (
    <registry.Provider>
      <Suspense>
        <URLWatcher />
      </Suspense>
      {children}
    </registry.Provider>
  );
};
