import Card from '../Card';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { useView } from '@/lib/store/hooks';
import { isEntry } from '@/lib/parsers/entries';
import { useTranslations } from 'next-intl';

export type CollaboratorCardProps = EntryCardProps<'collaborator'>;

export const CollaboratorCard = ({ item, ...props }: CollaboratorCardProps) => {
  const { pageTitle, title, entrySingle, linkField, entryImage } = item;
  const externalLink = maybeGet(linkField, 'href');
  const internalLink = maybeGet(entrySingle, 'uri');
  const t = useTranslations('common');

  const href = externalLink ?? internalLink ?? '';

  const page = useView();
  const isCommunity = isEntry(page, 'communityIndex');

  return (
    <Card
      elevation={false}
      fullHeight
      paper
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        image: entryImage ?? entrySingle?.entryImage,
      }}>
      <Card.Image alt="" ratio="landscape" />
      <Card.Body cx={{ mT: 'md', rowGap: 'xs' }}>
        <Card.Title variant={isCommunity ? 'h5' : 'h6'} as="span" />
        {isCommunity && (
          <Card.Cta as="div" endIcon={externalLink ? 'arrowOutward' : undefined}>
            {externalLink ? t('visitWebsite') : internalLink ? t('findOutMore') : undefined}
          </Card.Cta>
        )}
      </Card.Body>
    </Card>
  );
};
