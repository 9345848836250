'use client';

import { useTranslations } from 'next-intl';
import React from 'react';
import Box from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import { DEFAULT_LIMIT_INCREASE, useEntryIndex } from './useEntryIndex';
import { useEntryIndexPagination } from './useEntryIndexPagination';

export type EntryIndexPaginationProps = FlexProps<
  'div',
  {
    BtnProps?: BtnProps;
  }
>;

const EntryIndexPagination = ({ BtnProps, ...props }: EntryIndexPaginationProps) => {
  const { limit, setLimit, totalResults } = useEntryIndexPagination();
  const loading = useEntryIndex((s) => s.loading);
  const t = useTranslations('entryIndex');
  const buttonState = limit <= totalResults;

  const handleClick = (ev: React.ChangeEvent<unknown>) => {
    ev.preventDefault();
    setLimit(limit + DEFAULT_LIMIT_INCREASE);
  };

  return (
    (buttonState || loading) && (
      <Flex alignItems="center" cx={{ mY: 'xl' }} {...props}>
        <Box>
          <Btn variant="filled" color="accent" onClick={handleClick} {...BtnProps}>
            {t('loadMore')}
          </Btn>
        </Box>
      </Flex>
    )
  );
};

export default EntryIndexPagination;
