import { useEntryIndex } from './useEntryIndex';

export const useEntryIndexPagination = () => {
  const { items, limit, setLimit } = useEntryIndex((s) => ({
    items: s.items,
    limit: s.limit,
    setLimit: s.setLimit,
    total: s.totalPages,
  }));
  const totalResults = items.length;
  return {
    limit,
    setLimit,
    totalResults,
  };
};
