'use client';

import { ElementTreeItem } from '@/lib/parsers/common/parseElementList';
import { vars } from '@/theme/vars.css';
import { FormControl, MenuItem, MenuItemProps, Select, SelectChangeEvent } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';
import Box from '../ui/Box';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import { FilterSliceState } from './entryIndexTypes';
import { useEntryIndexFilter, useEntryIndexFilterItem } from './useEntryIndexFilter';

type FilterItemProps = {
  item: ElementTreeItem;
} & MenuItemProps;

const FilterItem = React.forwardRef(function MuiMenuItem(
  { item, ...props }: FilterItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { change } = useEntryIndexFilterItem(item);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      (document.activeElement as HTMLElement).blur();
      change();
    }
  };

  return (
    <MenuItem ref={ref} onKeyDown={handleKeyDown} sx={{ p: 0, display: 'block' }} {...props}>
      <Box onClick={change} cx={{ width: 'full' }}>
        {props.children}
      </Box>
    </MenuItem>
  );
});

export type EntryIndexFilterProps = {
  filterId: string;
};

const EntryIndexFilter = ({ filterId }: EntryIndexFilterProps) => {
  const { itemTree, clear } = useEntryIndexFilter(filterId);
  const [value, setValue] = useState<string>('');
  const t = useTranslations('entryIndex');

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return itemTree.length ? (
    <FormControl fullWidth>
      <Txt as="p" variant="body" autoMargin>
        {t('filterBy')}
      </Txt>
      <Select
        id={filterId}
        defaultValue=""
        disableUnderline
        value={value}
        variant="filled"
        onChange={handleChange}
        displayEmpty
        style={{
          borderWidth: '1px',
          borderColor: vars.palette.colorSets.full.contrastText,
          borderStyle: 'solid',
        }}
        inputProps={{ sx: { py: vars.spacing['3xs'], pl: 0 } }}>
        <MenuItem
          aria-labelledby={filterId}
          sx={{ p: 0, display: 'block' }}
          value=""
          onClick={clear}>
          <Txt as="div" variant="body" cx={{ pY: '3xs', pX: 'sm' }}>
            {t('filterDefault')}
          </Txt>
        </MenuItem>
        {itemTree.map((item) => (
          <FilterItem key={item.id} value={item.title} item={item}>
            <Txt as="div" variant="body" cx={{ pY: '3xs', pX: 'sm' }}>
              {item.title}
            </Txt>
          </FilterItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

const EntryIndexFilters = ({ filters }: { filters: FilterSliceState[] }) => {
  return filters.map((filter) => {
    return (
      <Grid.Col key={filter.id} md={3}>
        <EntryIndexFilter filterId={filter.id} />
      </Grid.Col>
    );
  });
};

export default EntryIndexFilters;
