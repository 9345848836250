import { isEntry } from '@/lib/parsers/entries';
import Card from '../Card';
import Grid from '../ui/Grid';
import { ArticleCard } from './EntryCard.Article';
import { ProfileCard } from './EntryCard.Profile';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';
import { JobPositionCard } from './EntryCard.JobPosition';
import { InstagramPostCard } from './EntryCard.InstagramPost';

export const EntryCardDefault = (props: EntryCardProps) => {
  if (!props.item) return null;

  if (isEntry(props.item, 'article'))
    return <ArticleCard {...(props as EntryCardProps<'article'>)} />;
  if (isEntry(props.item, 'profile'))
    return <ProfileCard {...(props as EntryCardProps<'profile'>)} />;
  if (isEntry(props.item, 'jobPosition'))
    return <JobPositionCard {...(props as EntryCardProps<'jobPosition'>)} />;
  if (isEntry(props.item, 'instagramPost'))
    return <InstagramPostCard {...(props as EntryCardProps<'instagramPost'>)} />;

  const { uri: href, title, summary: description, entryImage: image } = props.item;

  return (
    <Card
      {...props}
      fullHeight
      disableLinkWrap
      elevation={false}
      item={{
        title,
        description,
        image,
        href,
      }}>
      <Grid>
        <Grid.Col md={6}>
          <Card.Image alt="" hover={false} ratio="landscape" />
        </Grid.Col>
        <Grid.Col offsetMd={1} md={4} cx={{ display: 'flex', alignItems: 'center' }}>
          <Card.Body cx={{ display: 'flex', rowGap: 'xs', mT: 'md' }}>
            <Card.Title hover={false} variant="h3" as="span" />
            <Card.Description />
            <Card.Cta as="a" href={href} variant="filled" color="accent" />
          </Card.Body>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default EntryCardDefault;
