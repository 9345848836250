'use client';

import { EntryIndexFilterRegistryProvider } from './Filters/useEntryIndexFilterRegistry';
import { createEntryIndexStore, EntryIndexContext, EntryIndexStore } from './useEntryIndex';
import { PropsWithChildren, useRef } from 'react';
import EntryIndexAutoSubmit from './EntryIndex.AutoSubmit';
import { IndexProps } from './entryIndexTypes';

export type EntryIndexProps = PropsWithChildren<IndexProps>;

const EntryIndexProvider = ({ children, ...props }: EntryIndexProps) => {
  const storeRef = useRef<EntryIndexStore>();

  if (!storeRef.current) {
    storeRef.current = createEntryIndexStore(props);
  }

  return (
    <EntryIndexContext.Provider value={storeRef.current}>
      <EntryIndexFilterRegistryProvider>
        <EntryIndexAutoSubmit />
        {children}
      </EntryIndexFilterRegistryProvider>
    </EntryIndexContext.Provider>
  );
};

export default EntryIndexProvider;
